.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.body .header {
  display: flex;
  background: #1e1e1e;
  flex-grow: 0;
  flex-shrink: 0;
  color: white;
  font-family: Helvetica, Arial;
  padding: 10px;
}

.body .header > * {
  align-self: center;
}

.body .content {
  display: flex;
  flex-grow: 1;
}

.body .content .diagram-layer {
  position: relative;
  flex-grow: 1;
}

.body .content .diagram-layer .srd-demo-canvas {
  height: 100%;
  min-height: 80vh;
  background-color: #3c3c3c !important;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    );
  background-size: 50px 50px;
}

.body .content .tray {
  min-width: 200px;
  background: #141414;
  flex-grow: 0;
  flex-shrink: 0;
}

.body .content .tray .tray-item {
  color: white;
  font-family: Helvetica, Arial;
  padding: 5px;
  margin: 0px 10px;
  border: solid 1px;
  border-radius: 5px;
  margin-bottom: 2px;
  cursor: pointer;
}
